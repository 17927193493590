import Profile from "./pages/Profile";

function App() {
    return (
        <div className="font-sans antialiased text-gray-900 leading-normal tracking-wider bg-cover">
            <Profile />
        </div>
    );
}

export default App;
